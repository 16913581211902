import React from "react";
import "../App.css";
import BottomTabBarItem from "./BottomTabBarItem";
import { Popover } from "@headlessui/react";
import { RightChevron } from "../assets/images";
import { mobileCheck } from "../functions";

const BottomTabBarMenu = ({
  tabs,
  enabled,
  openMenu,
  animationLoaded,
  isIntro = false,
}) => {
  if (!mobileCheck() && !animationLoaded) return null;
  return (
    <>
      <Popover.Group>
        <div
          className={`transition ease-in-out ${
            enabled ? "translate-x-0" : "translate-x-[-120%]"
          } ${
            isIntro ? "opacity-60" : ""
          } bottomMenu bottom-0 sm:left-0 sm:absolute sm:z-[1000] flex sm:flex-col sm:h-[100dvh] px-1 py-4 justify-center border-t-[1px] border-[#54555A] sm:border-t-0 `}
        >
          {tabs.map((tab, index) => {
            return (
              <BottomTabBarItem
                tab={tab}
                index={index}
                key={index}
                isIntro={isIntro}
                animationLoaded={animationLoaded}
              />
            );
          })}
        </div>
      </Popover.Group>
      {!enabled && (
        <div
          onClick={() => openMenu()}
          className="cursor-pointer bottomMenuRightIcon bottom-0 sm:left-0 sm:absolute sm:z-[1000] hidden sm:flex sm:flex-col sm:h-[100dvh] px-1 py-10 justify-center bg-black rounded-r-[10px] opacity-50 hover:opacity-100"
        >
          <RightChevron />
        </div>
      )}
    </>
  );
};

export default BottomTabBarMenu;
