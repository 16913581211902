import { useEffect } from "react";
import "../App.css";
import { irLogo } from "../assets/images";
import { sendScreenVisitEvent } from "../GoogleAnalytics";

function Splash() {
  useEffect(() => {
    sendScreenVisitEvent("splash_screen");
    setTimeout(() => {
      window.location.href = "/home";
    }, 2000);
  }, []);
  return (
    <div className="w-full h-[100dvh] flex flex-col flex-1 bg-white justify-center items-center">
      <img src={irLogo} className="sm:w-[400px] w-[300px]" />
      <p className="mt-6 sm:text-[13px] text-[9px]">
        Loading: Next Gen R-Series 315-355kW Watercooled
      </p>
      <div className="mt-10">
        <span class="relative flex h-6 w-6">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#D52B1E] opacity-75"></span>
          <span class="relative inline-flex rounded-full h-6 w-6 bg-[#D52B1E]"></span>
        </span>
      </div>
    </div>
  );
}

export default Splash;
