import React, { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";

function DesktopArModal({
  isSidebarEnabled,
  isViewItemSelected,
  setIsViewItemSelected,
  viewType,
}) {
  let interiorSrc = "https://expo-3d-cdn.exposim.io/dai/e160i-2/external.gltf";
  let interiorIosSrc =
    "https://expo-3d-cdn.exposim.io/dai/e160i-2/internal.usdz";

  let exteriorSrc = "https://expo-3d-cdn.exposim.io/dai/e160i-2/external.gltf";
  let exteriorIosSrc =
    "https://expo-3d-cdn.exposim.io/dai/e160i-2/external.usdz";

  let exteriorData = `%22src%22:%22${exteriorSrc}%22,%22ios-src%22:%22${exteriorIosSrc}%22`;
  let interiorData = `%22src%22:%22${interiorSrc}%22,%22ios-src%22:%22${interiorIosSrc}%22`;

  return (
    <Transition appear show={isViewItemSelected} as={Fragment}>
      <Dialog as="div" onClose={() => setIsViewItemSelected(false)}>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-[800px] h-[550px] ${
                  isSidebarEnabled ? "mr-[25vw]" : ""
                }  l-[10vw] transform drop-shadow overflow-hidden rounded-2xl bg-[#f2f2f2] shadow-xl transition-all`}
              >
                <div
                  id="qr-code"
                  className={` w-full h-full px-[40px] items-center flex absolute`}
                >
                  <div className="w-[400px] h-[400px] px-[32px] bg-white flex items-center">
                    <img
                      // className="w-[400px] h-[450px] "
                      className=""
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=https://modal-viewer.vercel.app/?data={${
                        viewType === "exterior" ? exteriorData : interiorData
                      }}`}
                    />
                  </div>
                  <div className="w-[400px] h-[450px] justify-center items-center">
                    <div className="text-center self-center mt-[12px] font-semibold ">
                      Scan QR Code to view in AR
                    </div>
                    <div className="text-sm px-[30px] mt-[24px] ">
                      Point your mobile device camera at the QR code to see
                      Ingersoll Rand's E-Series 90-160KW Compressor in your
                      space using Augumented Reality.
                    </div>
                    <div className="text-sm px-[30px] mt-[24px]">
                      Augumented Reality is currently supported on Google
                      Android 8.0+ with ARCore 1.9+ and Apple iOS 11+ with ARKit
                      3+ enabled devices uing their native browsers.{" "}
                    </div>
                    <div className="flex justify-center mt-[24px]">
                      <button
                        onClick={() => setIsViewItemSelected(false)}
                        className=" font-medium bg-[#E1251B] self-center text-white px-5 py-[2px] rounded-md "
                      >
                        {" "}
                        Ok, Got it!
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default DesktopArModal;
