import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { arIcon, unsupportedAr } from "../assets/images";

function MobileArModel({
  isViewItemSelected,
  setIsViewItemSelected,
  checkForAr,
}) {
  return (
    <Transition appear show={isViewItemSelected} as={Fragment}>
      <Dialog onClose={() => setIsViewItemSelected(false)} as="div">
        <div className="fixed inset-0 w-[80vw] h-[30vh] top-[140px] left-[10vw] py-[12px] bg-[#f2f2f2] rounded-md">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={`w-full h-full rounded-md`}>
              <div className="h-full justify-around flex flex-col">
                <div className="flex justify-center items-center pl-[20px]">
                  <img
                    src={checkForAr ? arIcon : unsupportedAr}
                    className="h-[50px] w-[50px] "
                  />
                  <div className="text-xs font-medium px-[16px] leading-normal ">
                    {checkForAr
                      ? "This will load Ingersoll Rand’s E-Series 90-160 kW compressor in your space using Augmented Reality."
                      : "Augmented Reality is not supported on this device. Use a device with Google Android 8.0+ with ARCore 1.9+ or Apple iOS 11+ with ARKit 3+ enabled devices using their native browsers."}
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={
                      checkForAr
                        ? () => {
                            let viewer =
                              window.document.getElementById("model-viewer");
                            viewer.activateAR();
                            setIsViewItemSelected(false);
                          }
                        : () => setIsViewItemSelected(false)
                    }
                    className=" font-medium bg-[#E1251B] self-center text-white px-6 py-[2px] rounded-md "
                  >
                    Ok, Got it!
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default MobileArModel;
