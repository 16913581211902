import { Swiper, SwiperSlide } from "swiper/react";
import CloseIcon from "./CloseIcon";
import { Autoplay, Pagination } from "swiper";
import {
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
} from "../assets/images/slides";
import { useStateValue } from "../StateProvider";

const HighlightModal = ({ setenablemodal, modalContent }) => {
  const [{ translation }] = useStateValue();
  let highlights = [
    {
      id: 0,
      image: slide1,
    },
    {
      id: 1,
      image: slide2,
    },
    {
      id: 2,
      image: slide3,
    },
    {
      id: 3,
      image: slide4,
    },
  ];
  let initSlideNumber = highlights.findIndex((men) => men.id === modalContent);
  return (
    <div className="absolute top-0 w-full h-full bg-[rgba(0,0,0,0.7)] z-[100] flex justify-center items-center">
      <div className="w-[90%] sm:w-[40%] h-[60%]">
        <div
          className="asbsolute top-4 right-4 absolute right-2 rounded-full cursor-pointer"
          onClick={() => setenablemodal(false)}
        >
          <CloseIcon size="25px" color="#fff" />
        </div>
        <div className="flex flex-1 h-[-webkit-fill-available] bg-white rounded-[10px]">
          <>
            <div className="imageSwiper flex flex-1 flex-col overflow-y-scroll px-1">
              <div class="">
                <Swiper
                  loop={true}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Autoplay]}
                  className="productSwiper"
                  slidesPerView={1}
                  spaceBetween={30}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  initialSlide={initSlideNumber}
                  style={{ zIndex: 100 }}
                >
                  {highlights &&
                    highlights.map((hlight, hindex) => {
                      return (
                        <SwiperSlide key={hindex}>
                          <div className="flex flex-col z-[1] w-[95%] aspect-square px-6 pt-4 cursor-pointer">
                            <p className="text-[#e1241b] text-[14px] text-left mb-2">
                              {translation.descriptionText[hlight.id].title ? (
                                <p className="font-bold">
                                  {translation.descriptionText[hlight.id].title}
                                </p>
                              ) : (
                                ""
                              )}
                            </p>
                            <div className="flex justify-center items-center border-2 border-[#9caac2] rounded-[5px] h-[150px] mt-2">
                              <img
                                src={hlight.image}
                                className="w-[50%] aspect-auto"
                                data-fancybox="gallery"
                              />
                            </div>
                            <div className="flex-1">
                              <p className="text-[#5f6065] text-[14px] text-left mb-2 mt-4">
                                {translation.descriptionText[hlight.id].content}
                              </p>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
export default HighlightModal;
