import React, { useEffect } from "react";

function ModalViewer({
  isViewItemSelected,
  checkForAr,
  setCheckForAr,
  viewType,
}) {
  const modelRef = React.useRef();

  useEffect(() => {
    if (isViewItemSelected) {
      if (modelRef?.current?.canActivateAR) {
        setCheckForAr(true);
      } else {
        setCheckForAr(false);
      }
    }
  }, [isViewItemSelected]);

  return (
    <model-viewer
      src={
        viewType === "exterior"
          ? window.modelViewerData.exteriorSrc
          : window.modelViewerData.interiorSrc
      }
      ios-src={
        viewType === "exterior"
          ? window.modelViewerData.exteriorIosSrc
          : window.modelViewerData.interiorIosSrc
      }
      alt=""
      shadow-intensity="1"
      id="model-viewer"
      camera-controls
      auto-rotate
      ar
      reveal="manual"
      ref={modelRef}
    ></model-viewer>
  );
}

export default ModalViewer;
