import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, FreeMode, Pagination } from "swiper";
import { useStateValue } from "../StateProvider";
import "../App.css";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Switch from "react-switch";

//slides
import {
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
} from "../assets/images/slides";
import {
  NextHotspotButton,
  PrevHotspotButton,
  RightIcon,
  CloseIcon,
  BackIcon,
  BackIconNew,
  ExitViewIconWhite,
} from "../assets/images";
import { actionTypes } from "../reducer";
import { sendButtonClickEvent } from "../GoogleAnalytics";

const SideMenu = ({
  enabled,
  scenes,
  iframeRef,
  selectedView,
  setselectedView,
  closeSideMenu,
  clickedHotspot,
  isInfoButtonClicked,
  setisInfoButtonClicked,
  openTabBarMenu,
  changeSideMenuView,
  setIsHotspotEnabled,
  isHotspotEnabled,
  sendMessageToIframe,
}) => {
  const [{ hotspotViewSelected, lang, isInsideHotspotView }, dispatch] =
    useStateValue();
  const [option, setoption] = useState(null);
  const [hotspotOption, sethotspotOption] = useState(null);
  const [{ translation }] = useStateValue();
  const [checked, setChecked] = useState(false);
  const menu = [
    { name: translation.descriptionTitle, option: 1 },
    { name: translation.featuresTitle, option: 2 },
  ];
  useEffect(() => {
    setoption(null);
    sethotspotOption(null);
  }, [scenes]);
  useEffect(() => {
    if (isInfoButtonClicked === true) {
      setoption(1);
      sethotspotOption(null);
      setisInfoButtonClicked(false);
    }
  }, [isInfoButtonClicked]);
  const hotspots =
    scenes && scenes.length > 0
      ? selectedView === "Exterior"
        ? [...scenes[0].exteriorHotspots]
        : selectedView === "Interior"
        ? [...scenes[0].interiorHotspots]
        : [...scenes[0].sideHotspots]
      : [];

  useEffect(() => {
    setoption(2);
    sethotspotOption(clickedHotspot);
  }, [clickedHotspot]);

  useEffect(() => {
    sethotspotOption(null);
  }, [changeSideMenuView]);

  let highlights = [
    {
      id: 0,
      image: slide1,
    },
    {
      id: 1,
      image: slide2,
    },
    {
      id: 2,
      image: slide3,
    },
    {
      id: 3,
      image: slide4,
    },
  ];

  const ProductDescription = () => {
    return (
      <div className="imageSwiper">
        <div class="px-3">
          <Swiper
            loop={true}
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            className="productSwiper"
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
          >
            {highlights &&
              highlights.map((hlight, hindex) => {
                return (
                  <SwiperSlide key={hindex}>
                    <div className="flex flex-col w-full bg-white justify-center items-center p-2 cursor-pointer">
                      <img
                        src={hlight.image}
                        className="w-full aspect-auto"
                        data-fancybox="gallery"
                      />
                      <p className="text-[#54555A] text-[14px] text-left mt-6">
                        {translation.descriptionText[hlight.id].title ? (
                          <p className="font-bold">
                            {translation.descriptionText[hlight.id].title}
                          </p>
                        ) : (
                          ""
                        )}
                        {translation.descriptionText[hlight.id].content}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    );
  };

  const HotspotList = () => {
    return (
      <div className="overflow-auto grow basis-0">
        {hotspots.map((mn) => {
          let hotspotLanguageData = mn.languages.filter(
            (h) => h.langugageID.toLowerCase() === lang.key
          );
          let hotspotTranslatedData =
            hotspotLanguageData.length > 0
              ? hotspotLanguageData[0]
              : hotspot.languages
              ? hotspot.languages[0]
              : null;
          return (
            <div
              key={mn.id}
              onClick={() => {
                const isInterior =
                  scenes[0].interiorHotspots.filter((ih) => ih.id === mn.id)
                    .length > 0;
                const isExterior =
                  scenes[0].exteriorHotspots.filter((ih) => ih.id === mn.id)
                    .length > 0;
                const isSide =
                  scenes[0].sideHotspots.filter((ih) => ih.id === mn.id)
                    .length > 0;
                if (isInterior) {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "MoveToInterior" },
                    "*"
                  );
                  setselectedView("Interior");
                }
                if (isExterior) {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "MoveToExterior" },
                    "*"
                  );
                  setselectedView("Exterior");
                }
                if (isSide) {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "MoveToSide" },
                    "*"
                  );
                }
                iframeRef.current.contentWindow.postMessage(
                  {
                    func: "HotspotClicked",
                    parameter: mn.id,
                  },
                  "*"
                );
                sethotspotOption(mn.id);
                dispatch({
                  type: actionTypes.SET_INSIDE_VIEW,
                  value: true,
                  id: mn.id,
                });
                sendButtonClickEvent("hotspot_element", {
                  element: hotspotTranslatedData.heading,
                  from: "menu",
                });
              }}
              className="border-b-[1px] border-[#919194] py-[20px] pl-6 pr-4 flex justify-between cursor-pointer hover:bg-[#D52B1E] hotspotRow items-center"
            >
              <p
                className={`flex-1 ${
                  hotspotViewSelected === mn.id
                    ? "text-[#D52B1E]"
                    : "text-[#54555A]"
                } font-bold`}
              >
                {hotspotTranslatedData.heading}
              </p>
              <div className="w-[30px] h-[30px] ">
                <RightIcon />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const HotspotDetail = () => {
    let hotspot = hotspots.filter((h) => h.id === hotspotOption)[0];
    if (hotspot) {
      let videos = hotspot.videos;
      let hotspotLanguageData = hotspot.languages.filter(
        (h) => h.langugageID.toLowerCase() === lang.key
      );
      let hotspotTranslatedData =
        hotspotLanguageData.length > 0
          ? hotspotLanguageData[0]
          : hotspot.languages
          ? hotspot.languages[0]
          : null;
      return (
        <div className="overflow-auto grow basis-0">
          <div className="imageSwiper flex flex-1 flex-col overflow-y-scroll">
            <div className="justify-center items-start p-6">
              <p className="text-black text-[16px] text-left">
                {hotspotTranslatedData && hotspotTranslatedData.description}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  const RenderMenu = () => {
    return (
      <>
        <div className="flex flex-1 border-b-[1px] border-[#919194] overflow-auto grow basis-0 p-[25px]">
          {translation.highlightContent &&
            translation.highlightContent.map((hContent) => {
              return (
                <p className="text-black text-[16px] text-left">
                  {hContent.text}
                </p>
              );
            })}
        </div>
        {menu.map((mn, i) => {
          return (
            <div
              onClick={() => {
                setoption(mn.option);
              }}
              key={i}
              className="border-b-[1px] border-[#919194] py-[20px] px-[25px] flex justify-between cursor-pointer"
            >
              <p className="text-[#54555A] text-[18px] font-bold">{mn.name}</p>
              <div className="w-[30px] h-[30px]">
                <RightIcon />
              </div>
            </div>
          );
        })}
      </>
    );
  };
  let index = hotspots.findIndex((element) => element.id === hotspotOption);
  const nextIndex = index >= hotspots.length - 1 ? 0 : index + 1;
  const prevIndex = index === 0 ? hotspots.length - 1 : index - 1;
  let hotspot =
    hotspotOption === null
      ? null
      : hotspots.filter((h) => h.id === hotspotOption)[0];
  let hotspotLanguageData = hotspot
    ? hotspot.languages.filter((h) => h.langugageID.toLowerCase() === lang.key)
    : null;
  let hotspotTranslatedData = hotspotLanguageData
    ? hotspotLanguageData.length > 0
      ? hotspotLanguageData[0]
      : hotspot.languages
      ? hotspot.languages[0]
      : null
    : null;
  return (
    <div
      className={`transition ease-in-out bg-white w-3/12 flex-col z-[10] relative ${
        enabled
          ? "translate-x-0 hidden sm:flex"
          : "translate-x-[120%] hidden sm:flex"
      }`}
    >
      <div
        className="bg-[#E1251B] px-2 py-4 w-[40px] h-[40px] flex justify-center items-center absolute left-[-40px] top-[15px] z-[10000] rounded-l-[10px] cursor-pointer"
        onClick={() => {
          // sethotspotOption(null);
          sendButtonClickEvent("close_menu_button");
          closeSideMenu();
          openTabBarMenu(true);
        }}
      >
        <div className="w-[20px] h-[20px]">
          <CloseIcon />
        </div>
      </div>
      <div>
        {option === null ? (
          <div
            className={`flex min-h-[70px] p-[10px] justify-center items-center ${
              option === null && "pl-[10px]"
            } border-b-[1px] border-[#919194]`}
          >
            {option !== null && (
              <div
                className="w-[50px] h-[50px] flex justify-center items-center cursor-pointer"
                onClick={() => {
                  sendButtonClickEvent("back_menu_button");
                  if (option !== null) {
                    if (hotspotOption === null) {
                      setoption(null);
                    } else {
                      sethotspotOption(null);
                    }
                  }
                }}
              >
                <BackIcon />
              </div>
            )}
            <div className="flex-1 justify-center items-center px-[20px]">
              <p className="text-left text-[#E42313] font-bold text-[16px]">
                {translation.name}
              </p>
            </div>
          </div>
        ) : option === 1 ? (
          <div className="mb-[10px] border-b-[1px] border-[#919194] p-4 bg-[#fff] flex pl-[25px] justify-between items-center">
            <p className="text-[#E1251B] text-[18px] font-bold text-left flex-1 mr-[10px]">
              {translation.descriptionTitle}
            </p>
            <div className="w-[30px] h-[30px]">
              <BackIconNew
                className="cursor-pointer"
                onClick={() => {
                  sendButtonClickEvent("back_menu_button");
                  if (option !== null) {
                    if (hotspotOption === null) {
                      setoption(null);
                    } else {
                      sethotspotOption(null);
                    }
                  }
                }}
              />
            </div>
          </div>
        ) : hotspotOption === null ? (
          <div className="border-b-[1px] border-[#919194] p-4 bg-[#fff] flex pl-[25px] justify-between items-center">
            <p className="text-[#E1251B] text-[18px] font-bold text-left flex-1 mr-[10px]">
              {translation.featuresTitle}
            </p>
            <div className="w-[30px] h-[30px]">
              <BackIconNew
                className="cursor-pointer"
                onClick={() => {
                  sendButtonClickEvent("back_menu_button");
                  if (option !== null) {
                    if (hotspotOption === null) {
                      setoption(null);
                      openTabBarMenu(true);
                      // iframeRef.current.contentWindow.postMessage(
                      //   { func: "ExitHotspotView" },
                      //   "*"
                      // );
                      // dispatch({
                      //   type: actionTypes.SET_INSIDE_VIEW,
                      //   value: false,
                      //   id: null,
                      // });
                    } else {
                      sethotspotOption(null);
                    }
                  }
                  console.log("hotspotOption", hotspotOption);
                  console.log("option", option);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="mb-[10px] border-b-[1px] border-[#919194] p-4 bg-[#fff] flex pl-[25px] justify-between items-center">
            <p className="text-[#E1251B] text-[18px] font-bold text-left flex-1 mr-[10px]">
              {hotspotTranslatedData && hotspotTranslatedData.heading}
            </p>
            <div className="w-[30px] h-[30px]">
              <BackIconNew
                className="cursor-pointer"
                onClick={() => {
                  sendButtonClickEvent("back_menu_button");
                  if (option !== null) {
                    if (hotspotOption === null) {
                      setoption(null);
                      if (selectedView === "Exterior") {
                        iframeRef.current.contentWindow.postMessage(
                          { func: "MoveToInterior" },
                          "*"
                        );
                        setselectedView("Interior");
                      }
                      if (selectedView === "Interior") {
                        iframeRef.current.contentWindow.postMessage(
                          { func: "MoveToExterior" },
                          "*"
                        );
                        setselectedView("Exterior");
                      }
                    } else {
                      sethotspotOption(null);
                    }
                  }
                  if (!isInsideHotspotView) {
                    setTimeout(() => {
                      if (!isHotspotEnabled) {
                        sendMessageToIframe("DisableAllHotspots");
                        setIsHotspotEnabled(false);
                      } else {
                        sendMessageToIframe("EnableAllHotspots");
                        setIsHotspotEnabled(true);
                      }
                    }, 850);
                  }
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="middleContainer flex flex-col flex-1">
        {option === null
          ? RenderMenu()
          : option === 1
          ? ProductDescription()
          : hotspotOption === null
          ? HotspotList()
          : HotspotDetail()}
      </div>
      {hotspotOption === null && option === 2 && (
        <div className="border-t-[2px] border-[#919194] px-[25px] py-3 flex justify-center items-center">
          <Switch
            onChange={(nextChecked) => {
              if (!isInsideHotspotView) {
                if (selectedView === "Exterior") {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "MoveToInterior" },
                    "*"
                  );
                }
                if (selectedView === "Interior") {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "MoveToExterior" },
                    "*"
                  );
                }
              }
              if (selectedView === "Exterior") {
                setselectedView("Interior");
                sendButtonClickEvent("view_change_switch_button", {
                  option: "interior",
                });
              }
              if (selectedView === "Interior") {
                setselectedView("Exterior");
                sendButtonClickEvent("view_change_switch_button", {
                  option: "exterior",
                });
              }
              if (!isInsideHotspotView) {
                setTimeout(() => {
                  if (!isHotspotEnabled) {
                    sendMessageToIframe("DisableAllHotspots");
                    setIsHotspotEnabled(false);
                  } else {
                    sendMessageToIframe("EnableAllHotspots");
                    setIsHotspotEnabled(true);
                  }
                }, 850);
              }
            }}
            checked={selectedView === "Exterior"}
            className="react-switch"
            width={160}
            height={38}
            offColor="#E1251B"
            onColor="#E1251B"
            uncheckedIcon={
              <div className="flex flex-1 justify-center items-center">
                <p className="text-[14px] font-bold text-white">
                  {translation.interior}
                </p>
              </div>
            }
            checkedIcon={
              <div className="flex flex-1 justify-center items-center">
                <p className="text-[14px] font-bold text-white">
                  {translation.exterior}
                </p>
              </div>
            }
          />
        </div>
      )}
      {hotspotOption !== null && (
        <div className="border-t-[2px] border-[#919194] flex justify-between px-[25px] py-3 flex justify-center items-center">
          <div
            className={`w-[30px] cursor-pointer ${index === 0 ? "" : ""}`}
            onClick={() => {
              sendButtonClickEvent("previous_hotspot_button");
              const isInterior =
                scenes[0].interiorHotspots.filter(
                  (ih) => ih.id === hotspots[prevIndex].id
                ).length > 0;
              const isExterior =
                scenes[0].exteriorHotspots.filter(
                  (ih) => ih.id === hotspots[prevIndex].id
                ).length > 0;
              const isSide =
                scenes[0].sideHotspots.filter(
                  (ih) => ih.id === hotspots[prevIndex].id
                ).length > 0;
              if (isInterior) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToInterior" },
                  "*"
                );
                setselectedView("Interior");
              }
              if (isExterior) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToExterior" },
                  "*"
                );
                setselectedView("Exterior");
              }
              if (isSide) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToSide" },
                  "*"
                );
                setselectedView("Side");
              }
              iframeRef.current.contentWindow.postMessage(
                {
                  func: "HotspotClicked",
                  parameter: hotspots[prevIndex].id,
                },
                "*"
              );
              sethotspotOption(hotspots[prevIndex].id);
              if (!isInsideHotspotView) {
                setTimeout(() => {
                  if (!isHotspotEnabled) {
                    sendMessageToIframe("DisableAllHotspots");
                    setIsHotspotEnabled(false);
                  } else {
                    sendMessageToIframe("EnableAllHotspots");
                    setIsHotspotEnabled(true);
                  }
                }, 850);
              }
            }}
          >
            <PrevHotspotButton />
          </div>
          <div
            className={`w-[30px] cursor-pointer ${
              index === hotspots.length - 1 ? "" : ""
            }`}
            onClick={() => {
              sendButtonClickEvent("next_hotspot_button");
              const isInterior =
                scenes[0].interiorHotspots.filter(
                  (ih) => ih.id === hotspots[nextIndex].id
                ).length > 0;
              const isExterior =
                scenes[0].exteriorHotspots.filter(
                  (ih) => ih.id === hotspots[nextIndex].id
                ).length > 0;
              const isSide =
                scenes[0].sideHotspots.filter(
                  (ih) => ih.id === hotspots[nextIndex].id
                ).length > 0;
              if (isInterior) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToInterior" },
                  "*"
                );
                setselectedView("Interior");
              }
              if (isExterior) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToExterior" },
                  "*"
                );
                setselectedView("Exterior");
              }
              if (isSide) {
                iframeRef.current.contentWindow.postMessage(
                  { func: "MoveToSide" },
                  "*"
                );
              }
              iframeRef.current.contentWindow.postMessage(
                {
                  func: "HotspotClicked",
                  parameter: hotspots[nextIndex].id,
                },
                "*"
              );
              sethotspotOption(hotspots[nextIndex].id);
              if (!isInsideHotspotView) {
                setTimeout(() => {
                  if (!isHotspotEnabled) {
                    sendMessageToIframe("DisableAllHotspots");
                    setIsHotspotEnabled(false);
                  } else {
                    sendMessageToIframe("EnableAllHotspots");
                    setIsHotspotEnabled(true);
                  }
                }, 850);
              }
            }}
          >
            <NextHotspotButton />
          </div>
          <div
            className={`w-[30px] h-[30px] flex justify-center items-center cursor-pointer rounded-full bg-[#E1251B]`}
            onClick={() => {
              sendButtonClickEvent("back_to_hotspotlist_button");
              if (option !== null) {
                if (hotspotOption === null) {
                  setoption(null);
                } else {
                  iframeRef.current.contentWindow.postMessage(
                    { func: "ExitHotspotView" },
                    "*"
                  );
                  // iframeRef.current.contentWindow.postMessage(
                  //   { func: "MoveToExterior" },
                  //   "*"
                  // );
                  // setselectedView("Exterior");
                  sethotspotOption(null);
                  dispatch({
                    type: actionTypes.SET_INSIDE_VIEW,
                    value: false,
                    id: null,
                  });
                  setTimeout(() => {
                    if (!isHotspotEnabled) {
                      sendMessageToIframe("DisableAllHotspots");
                      setIsHotspotEnabled(false);
                    } else {
                      sendMessageToIframe("EnableAllHotspots");
                      setIsHotspotEnabled(true);
                    }
                  }, 850);

                  // openTabBarMenu(true);
                }
              }
            }}
          >
            <ExitViewIconWhite />
          </div>
        </div>
      )}
      <div className="border-t-[2px] border-[#919194] flex justify-center items-center p-4">
        <div className="rounded-r-[5px] flex flex-1 h-[35px] justify-between">
          <div
            className="flex flex-1 p-[5px] items-center justify-center rounded-[10px] cursor-pointer hover:bg-[#D52B1E] hoverP bg-[#C8C8C8]"
            onClick={() => {
              sendButtonClickEvent("contact_us_button");
              window.open(
                "https://www.ingersollrand.com/modals/contact",
                "_blank"
              );
            }}
          >
            <p className="text-[13px] leading-none text-[#54555A] text-center font-bold">
              {translation.contactUs}
            </p>
          </div>

          <div
            className="flex flex-1 p-[5px] items-center justify-center rounded-[10px] cursor-pointer hover:bg-[#D52B1E] hoverP bg-[#C8C8C8] mx-3"
            onClick={() => {
              sendButtonClickEvent("request_support_button");
              window.open(
                "https://www.ingersollrand.com/modals/aftermarket",
                "_blank"
              );
            }}
          >
            <p className="text-[13px] leading-none text-[#54555A] text-center font-bold">
              {translation.requestSupport}
            </p>
          </div>
          <div
            className="flex flex-1 p-[5px] items-center justify-center rounded-[10px] cursor-pointer hover:bg-[#D52B1E] hoverP bg-[#C8C8C8]"
            onClick={() => {
              sendButtonClickEvent("request_quote_button");
              window.open(
                "https://www.ingersollrand.com/modals/request-a-quote",
                "_blank"
              );
            }}
          >
            <p className="text-[13px] leading-none text-[#54555A] text-center font-bold">
              {translation.requestAQuote}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
