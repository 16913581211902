import React from "react";
import { Popover } from "@headlessui/react";
import { arIcon } from "../assets/images";

function ViewArModel({
  isSidebarEnabled,
  isViewItemSelected,
  setIsViewItemSelected,
  setViewType,
}) {
  return (
    <Popover>
      {({ open, close }) => {
        return (
          <>
            <Popover.Button>
              <div
                className={`absolute mt-2 bottom-6 flex flex-col items-center ${
                  isSidebarEnabled ? "right-[32%]" : "right-20"
                }  hover:cursor-pointer`}
              >
                <div
                  //   onClick={() => setIsViewArPressed(!isViewArPressed)}
                  className={` flex flex-row mt-2 rounded-md px-2 border-2 border-[#E1251B] ${
                    isSidebarEnabled ? "right-[35%]" : "right-25"
                  } py-[2px] `}
                >
                  <img
                    className="w-[18px] h-[18px] mr-[4px] my-[2px]"
                    src={arIcon}
                  />
                  <span className="text-[#E1251B] font-medium text-sm">
                    view in AR
                  </span>
                </div>
              </div>
            </Popover.Button>
            <Popover.Panel
              className={`absolute z-[1000] ${
                isSidebarEnabled ? "right-[30%]" : "right-6"
              } bottom-[70px]`}
            >
              <div className="menu-content right-[100%] sm:bottom-[0%] pr-10px">
                <div
                  onClick={() => {
                    setIsViewItemSelected(!isViewItemSelected);
                    setViewType("interior");
                    close();
                  }}
                  className={`menu-row py-2 hover:bg-[#D52B1E] border-b-[1px] hover:text-white flex justify-between items-center px-4 bg-white rounded-t-[10px] cursor-pointer`}
                >
                  <p className={`text-[14px]`}>Interior View</p>
                </div>
                <div
                  onClick={() => {
                    setIsViewItemSelected(!isViewItemSelected);
                    setViewType("exterior");
                    close();
                  }}
                  className={`menu-row py-2 hover:bg-[#D52B1E] hover:text-white flex justify-between items-center px-4 bg-white rounded-b-[10px] border-b-0 cursor-pointer`}
                >
                  <p className={` text-[14px]`}>Exterior View</p>
                </div>
              </div>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
}

export default ViewArModel;
