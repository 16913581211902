import { Popover } from "@headlessui/react";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import { Tooltip } from "@mui/material";
import { sendButtonClickEvent } from "../GoogleAnalytics";

const DesktopLanguageButton = ({
  isSidebarEnabled,
  isEnableLangButton,
  menu,
  isIntro = false,
}) => {
  const [{ lang, translation }, dispatch] = useStateValue();
  return (
    <Popover>
      {({ open, close }) => {
        return (
          <>
            <Popover.Button
              onClick={() => {
                sendButtonClickEvent("language_button");
              }}
            >
              {isIntro ? (
                <Tooltip title={translation.languageText} arrow open={true}>
                  <div
                    className={`hidden sm:flex sm:absolute mt-2 sm:bottom-4 ${
                      isSidebarEnabled ? "right-[27%]" : "right-4"
                    } hover:text-white ${
                      isIntro || isEnableLangButton
                        ? "opacity-100"
                        : "opacity-50"
                    } hover:opacity-100`}
                  >
                    <span class="animate-ping ping-count absolute inline-flex h-full w-full rounded-full bg-[#D52B1E] opacity-30"></span>
                    <div
                      id="language"
                      className="opacity-60 buttonShadow menu flex items-center justify-center w-[50px] h-[50px] bg-white sm:bg-[#D52B1E] hover:bg-[#D52B1E] rounded-full cursor-pointer relative"
                    >
                      <p className="text-black sm:text-white text-[14px] uppercase sm:font-bold">
                        {lang.key}
                      </p>
                    </div>
                  </div>
                </Tooltip>
              ) : (
                  <div
                    className={`hidden sm:flex sm:absolute mt-2 sm:bottom-4 ${
                      isSidebarEnabled ? "right-[27%]" : "right-4"
                    } hover:text-white ${
                      isIntro || isEnableLangButton
                        ? "opacity-100"
                        : "opacity-50"
                    } hover:opacity-100`}
                  >
                    <span class="animate-ping ping-count absolute inline-flex h-full w-full rounded-full bg-[#D52B1E] opacity-30"></span>
                    <div
                      id="language"
                      className="buttonShadow menu flex items-center justify-center w-[50px] h-[50px] bg-white sm:bg-[#D52B1E] hover:bg-[#D52B1E] rounded-full cursor-pointer relative"
                    >
                      <p className="text-black sm:text-white text-[14px] uppercase sm:font-bold">
                        {lang.key}
                      </p>
                    </div>
                  </div>
              )}
            </Popover.Button>
            <Popover.Panel
              className={`absolute z-[1000] ${
                isSidebarEnabled ? "right-[27%]" : "right-4"
              } bottom-[70px]`}
            >
              {!isIntro && menu.length > 0 && (
                <div className="menu-content right-[100%] sm:bottom-[0%] pr-10px">
                  <div className={`rounded-[10px]`}>
                    {menu.map((men, mIndex) => {
                      return (
                        <div
                          key={mIndex}
                          onClick={() => {
                            sendButtonClickEvent("language_element", {
                              element: men.key,
                            });
                            dispatch({
                              type: actionTypes.SET_LANGUAGE,
                              lang: men,
                            });
                            close();
                          }}
                          className={`menu-row py-2 hover:bg-[#D52B1E] hover:text-white flex justify-between items-center px-4 ${
                            lang.key === men.key ? "bg-[#D52B1E]" : "bg-white"
                          } ${
                            mIndex === 0 ? "rounded-t-[10px]" : ""
                          } border-[#C8C8C8] ${
                            mIndex === menu.length - 1
                              ? "rounded-b-[10px] border-b-0"
                              : "border-b-[1px]"
                          } cursor-pointer`}
                        >
                          <p
                            className={`${
                              lang.key === men.key
                                ? "bg-[#D52B1E] text-white"
                                : "text-[#54555A]"
                            } text-[14px]`}
                          >
                            {men.label}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};

export default DesktopLanguageButton;
