import React from "react";
import { ReactComponent as AddIcon } from "../assets/images/addIcon.svg";
import { useStateValue } from "../StateProvider";
import {
  slide1,
  slide2,
  slide3,
  slide4,
  slide5,
  slide6,
  slide7,
  slide8,
} from "../assets/images/slides";
import { sendButtonClickEvent } from "../GoogleAnalytics";

const ProductHighlights = ({
  setmodalContent,
  setenablemodal,
  setenableDescription,
  enableDescription,
}) => {
  const [{ translation }] = useStateValue();
  let highlights = [
    {
      id: 0,
      image: slide1,
    },
    {
      id: 1,
      image: slide2,
    },
    {
      id: 2,
      image: slide3,
    },
    {
      id: 3,
      image: slide4,
    },
  ];
  return (
    <>
      <div className="imageSwiper z-0 flex flex-1 flex-col overflow-y-scroll px-1">
        <div class="">
          {highlights &&
            highlights.map((hlight, hindex) => {
              return (
                <div
                  key={hindex}
                  onClick={() => {
                    sendButtonClickEvent("product_highlights_element", {
                      element:
                        translation.descriptionText[hlight.id].title || "",
                    });
                    setmodalContent(hlight.id);
                    setenablemodal(true);
                  }}
                  className="h-[76px] flex z-[1] w-full bg-white justify-center items-center px-[15px] py-[10px] cursor-pointer border border-2 border-[#c8c8c8] mt-2 rounded-[10px] relative"
                >
                  <div className="w-[30%]">
                    <img src={hlight.image} className="aspect-auto" />
                  </div>
                  <div>
                    <p className="text-black font-bold text-[10px] text-left ml-4">
                      {translation.descriptionText[hlight.id].title
                        ? translation.descriptionText[hlight.id].title
                        : null}
                    </p>
                    <p className="text-black text-[10px] text-left ml-4 line-limit">
                      {translation.descriptionText[hlight.id].content}
                    </p>
                  </div>
                  <div className="rounded-full absolute right-1 top-1 p-1 bg-[#d9d9d9] aspect-square w-[25px] h-[25px] flex justify-center items-center">
                    <AddIcon />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div
        className="flex justify-end px-8 py-1 cursor-pointer hover:underline"
        onClick={() => {
          sendButtonClickEvent("product_details_button");
          setenableDescription(!enableDescription);
        }}
      >
        <p className="text-[14px] text-[#E1251B]">
          {translation.productDetails}
        </p>
        <p className="text-[14px] ml-2 text-[#E1251B]">{"<<"}</p>
      </div>
    </>
  );
};

export default ProductHighlights;
